import { IndexedIterable } from '@amcharts/amcharts4/core';
import { AppConsts } from '@shared/AppConsts';
import { StringNullableChain } from 'lodash';
import * as moment from 'moment';
import { QuestionType } from '../../../shared/AppEnums';
import { Product } from '../FactoryResources/ProductFactory/Product';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoverProposalsTableComponent } from '@app/main/my-resource-centre/cover-proposals/cover-proposals-table/cover-proposals-table.component';
import {INeedsCalculatorx} from '@shared/service-proxies/service-proxies';

export interface IBannerStage {
    stage?: string;
    innerStages: IBannerStageContent[];
}

export interface IBannerStageContent {
    stage?: string;
    type?: string;
    content: string;
    link?: string;
}

export class BannerStageContent implements IBannerStageContent {
    stage?: string;
    type?: string;
    content: string;
    link?: string;

    constructor(data?: IBannerStageContent) {
        if (data) {
            this.stage = data.stage;
            this.type = data.type;
            this.content = data.content;
            this.link = data.link;
        }
    }

    isInfo(): boolean { return this.type === 'info'; }

    isWarning(): boolean {  return this.type === 'warning'; }
}

export class BannerStage implements IBannerStage {
    stage?: string;
    innerStages: BannerStageContent[];

    constructor(data?: IBannerStage) {
        if (data) {
            this.stage = data.stage;
            this.innerStages = data.innerStages.map(rec => new BannerStageContent(rec));
        }
    }
}

export declare module BrokerDtoModule {

    export interface CreateBrokerModule {
        adminGuid?: string;
        idpGuid?: string;
        brokerageCode?: any;
        givenName?: any;
        familyName?: any;
        idNumber?: any;
        birthdate?: string;
        gender?: any;
        phoneNumber?: any;
        email?: any;
        address_1?: any;
        address_2?: any;
        address_3?: any;
        address_4?: any;
        postalCode?: any;
        supervisionIndicator?: boolean;
        qualifications?: any;
        experienceStart?: string;
        pI_CoverIndicator?: boolean;
        fI_CoverIndicator?: boolean;
        productSpecificTraining?: boolean;
        cpD_RequirementsMet?: boolean;
        activeStartDate?: string;
        activeEndDate?: string;
        professionID?: any;
        commissionType?: string;
        brokerConsultantIdpGuids?: Array<string>;
    }

}

export declare module BenficiaryModule {
    export interface IBeneficiaryModel {
        entityNumber?: string;
        id?: string;
        productName?: string;
        salutation?: string;
        firstName?: string;
        lastName?: string;
        identificationType?: string;
        idNumber?: string;
        relationshipToPolicyHolder?: string;
        phoneNumber?: string;
        email?: string;
        allocationPercentage?: number;
        policyGuid?: string;
        isPrimary?: boolean;
    }

    export interface IPolicicyBeneficiary {
        policyUid: string;
        policyName: string;
        quoteUid: string;
        products: Array<{
            productName: string,
            productUid: string,
            beneficiaries: Array<BenficiaryModule.IBeneficiaryModel>,
            deletedBeneficiaries: Array<string>,
            hasChanged: boolean,
            isMainProduct: boolean
        }>;
    }
}

export interface IClientDetailsModel {
    email: string;
    cellNumber: string;
    firstName: string;
    surName: string;
    idNumber?: string;
    dob?: Date;
    gender?: any;
}

export interface IEventSetOutput {
    ShortCode: string;
    DisplayName: string;
    selected?: boolean;
    UID?: string;
    ProductName?: string;
}

export interface IQuotePolicyProductModel {
    quoteId: string;
    policyId: string;
    productId?: string;
    productName?: string;
}

export interface IHttpResponseDetails {
    result: number;
    message?: any;
    sub?: any;
}

export interface IDirectSalesUserDto {
    gender?: any;
    dob: Date;
    cellNumber?: any;
    email?: any;
    identityNumber?: any;
    name: string;
    surname: string;
    brokerFirstName: string;
    brokerLastName: string;
    brokerEmail: string;
    senderGuid: string;
    isQuickQuote: boolean;
}

export interface ITemplateEmailDto {
    subjectLine: string;
    templateName: string;
    mergeTokens: { [id: string]: string; };
}

export declare module CreateOrUpdatePolicyInputModule {

    export interface IUpdatePolicyOfApplicantWithAnswers {
        CustomerReferenceid?: any;
        CedantId?: any;
        PolicyReferenceId?: any;
        ShowErrorDetails?: any;
        ProcessDisclosures?: any;
        DisplayAnswers?: any;
        AnsweredQuestions?: IAnsweredQuestion[];
    }

    export interface IAnsweredQuestion {
        QuestionProfileId?: number;
        Value?: any;
        ExternalReferenceId?: any;
        MultipleAnswers?: IMultipleAnswer[];
    }

    export interface IMultipleAnswer {
        Value?: any;
    }
}

export declare module CreateOrUpdatePolicyDtoOutpuModel {

    export interface IMultipleAnswer {
        Text: string;
        Value: any;
    }

    export interface IAnsweredQuestion {
        AnswerText?: string;
        AnswerRequired?: boolean;
        ExternalReferenceId?: string;
        MultipleAnswers?: IMultipleAnswer[];
        ParentSectionExtRefId?: string;
        ParentSectionId?: number;
        ParentSectionName?: string;
        QuestionProfileId?: number;
        QuestionText?: string;
        SectionExtRefId?: string;
        SectionId?: number;
        SectionName?: string;
        Text?: string;
        Title?: string;
        Value?: any;
    }



    export interface IUnAnsweredQuestion {
        AnswerRequired: boolean;
        CurrentText?: any;
        CurrentValue?: any;
        ExternalReferenceId: string;
        ParentSectionExtRefId: string;
        ParentSectionId: number;
        ParentSectionName: string;
        QuestionExtRefId: string;
        QuestionProfileId: number;
        SectionExtRefId: string;
        SectionId: number;
        SectionName: string;
        Text: string;
        Title: string;
    }

    export interface IPolicyDetail {
        ErrorDetails?: any;
        AnsweredQuestions: IAnsweredQuestion[];
        CustomerId: number;
        PolicyDetail: IPolicyDetail;
        PolicyId: number;
        PolicyReferenceId: string;
        PolicyStatus: number;
        PolicyStatusName: string;
        QuestionnaireId: number;
        QuestionnaireStatus: number;
        QuestionnaireStatusName: string;
        RedirectUrl: string;
        SectionDisclosures: any[];
        UnAnsweredQuestions: IUnAnsweredQuestion[];
        VersionId: number;
    }

    export interface ICreateOrUpdatePolicyDto {
        ErrorDetails?: any;
        PolicyDetail: IPolicyDetail;
    }

}

export declare module CreateOrUpdateApplicantDtoOutpuModel {

    export interface ICustomer {
        FirstName: string;
        Surname: string;
        NationalID: string;
        DateOfBirth: Date;
        EmailAddress: string;
        ExternalReference: string;
    }

    export interface ICreateOrUpdateApplicant {
        CedantId: string;
        Customer: ICustomer;
        ShowErrorDetails: string;
    }

}


export declare module GetQuestionariesModule {

    export interface IGroupedExistingCover {
        typeOfCover: string;
        totalCoverAmountKept: number;
    }

    export interface IExistingCover {
        index: number;
        groupId?: string;
        typeOfCover?: string;
        reasonForCover?: string;
        currentCoverAmount?: number;
        coverAmountKept?: number;
        coverBeingAltered: string;
    }

    export interface IOutCome {
        AnswerId: number;
        QuestionProfileId: number;
        SectionId: number;
        SectionVisibilityQuestionProfiles?: any;
        Visibility: string;
        id: number;
    }

    export interface IAnswer {
        AnswerSequence: number;
        AnswerType: string;
        ApplicantAttributeName: string;
        AutoComplete: boolean;
        DataType: string;
        OutComes: Array<IOutCome>;
        OverrideAnswer: boolean;
        Prompt: string;
        Text: string;
        Value: string;
        id: number;
        ExistingAnswer?: boolean;
    }

    export interface IQuestion {
        AllowMultiAnswers: boolean;
        Answer: IAnswer[];
        ExternalReferenceId: string;
        Id: number;
        IsVisibleAtStart: boolean;
        AnsweredStatus?: boolean;
        ValueGiven?: any;
        CondtionValues?: any;
        Prompt: string;
        QuestionSequence: number;
        Text: string;
        Title: string;
        QuestionType: QuestionType;
        valueCondtions?: any;
        AnswerRequired: boolean;
        overrideVisibility?: boolean;
    }

    export interface IQuestionGroup {
        GroupTitle: string;
        Questions: IQuestion[];
    }

    export interface ISection {
        ExternalReferenceId?: string;
        Id?: number;
        IsVisibleAtStart?: boolean;
        ParentId?: any;
        ParentSectionSequence?: number;
        PromptText?: string;
        Questions?: IQuestion[];
        SectionSequence?: any;
        Sections?: ISection[];
        Title?: string;
    }

    export interface IQuestionDetails {
        Cedants?: any;
        DateCreated: Date;
        Id: number;
        Locked: boolean;
        Name: string;
        Sections: ISection[];
        Version: string;
    }

    export interface IGetQuestionsForSectionOutputDto {
        ErrorDetails?: any;
        QuestionDetails: IQuestionDetails;
    }

}

export class ExistingCover implements GetQuestionariesModule.IExistingCover {
    constructor(index: number, groupId: string = null, typeOfCover: string = null, reasonForCover: string  = null, currentCoverAmount: number  = null, coverAmountKept: number  = null, coverBeingAltered: string = null) {
        this.index = index,
        this.groupId = groupId;
        this.typeOfCover = typeOfCover;
        this.reasonForCover = reasonForCover;
        this.currentCoverAmount = currentCoverAmount;
        this.coverAmountKept = coverAmountKept;
        this.coverBeingAltered = coverBeingAltered;

        this.typeOfCoverControl = new FormControl(this.typeOfCover, [Validators.required]);
        this.reasonForCoverControl = new FormControl(this.reasonForCover, [Validators.required]);
        this.currentCoverAmountControl = new FormControl(this.currentCoverAmount, [Validators.required, Validators.min(0)]);
        this.coverAmountKeptControl = new FormControl(this.coverAmountKept, [Validators.required, Validators.min(0)]);
        this.coverBeingAlteredControl = new FormControl(this.coverBeingAltered, [Validators.required]);


        this.formGroup = new FormGroup({
            typeOfCoverControl: this.typeOfCoverControl,
            reasonForCoverControl: this.reasonForCoverControl,
            currentCoverAmountControl: this.currentCoverAmountControl,
            coverAmountKeptControl: this.coverAmountKeptControl,
            coverBeingAlteredControl: this.coverBeingAlteredControl
        });
    }

    index: number;
    groupId?: string;

    formGroup: FormGroup;

    typeOfCover: string;
    typeOfCoverControl: FormControl;

    reasonForCover: string;
    reasonForCoverControl: FormControl;

    currentCoverAmount: number;
    currentCoverAmountControl: FormControl;

    coverAmountKept: number;
    coverAmountKeptControl: FormControl;

    coverBeingAltered: string;
    coverBeingAlteredControl: FormControl;
}

export declare module ClaimsModule {
    export interface IClaimsSummary {
        section: string;
        values: ISummaryValues[];
    }

    export interface ISummaryValues {
        mainHeading?: string;
        desc1?: string;
        desc2?: string;
        desc3?: string;
        listArr?: string[];
    }

    export interface IQuestionAnsType {
        item?: string;
        value?: string;
    }


    export interface IHealthStatus {
        diagnosis?: string;
        dateOfDiagnosis?: any;
        doctorDiagnose?: string;
        treatingGP?: string;
        treatingSpecialist?: string;
        speciality?: string;
    }

    export interface ISymptoms {
        symptoms?: string;
        whenDoYouNotice?: any;
        testConfirms?: boolean;
        specifyTest?: string;
        testDone?: any;
    }

    export interface ITreatMent {
        PopulatedTreatmentsHere?: string;
        hospitalizationDate?: any;
        futureTreatment?: any;
    }

    export interface IPersonalDetails {
        firstName?: string;
        lastName?: string;
        idNumber?: string;
        address?: string;
        emailAddress?: string;
        cellNumber?: string;
        alternativeNumber?: string;
    }

    export interface IOccupationalInfo {
        company?: string;
        jobtitle?: string;
        workAddress?: string;
        workNumber?: string;
        directNumber?: string;
    }

    export interface IMedicalPractionaire {
        medicalAid?: string;
        plan?: string;
        memberNo?: string;
        dateJoined?: string;
        familyGp?: string;
        gpContactNo?: string;
        reasonSeeingGp?: string;
    }
}

export declare module PoliciesModule {

    export interface IPolicy {
        policyGuid: string;
        policyNumber: string;
        quoteGuid: string;
        quoteNumber: string;
        inceptionDate: string;
        policyStatus: string;
        brokerGuid: string;
        brokerConsultantsAssigned: string;
        lifeAssuredGuid: string;
        premiumPayerGuid: string;
        rewardSubAccountGuid: string;
        lastUpdatedDate: Date;
        products: Array<IPolicyProduct>;
        policyCession?: IPolicyCession;
        policyRAR?: IPolicyRAR;
    }

    export interface IPolicyProduct {
        productGuid: string;
        policyGuid: string;
        productName: string;
        isMainProduct: boolean;
        sumAssured: number;
        premium: number;
        benefitPatternGuid: string;
        premiumPatternGuid: string;
        status: string;
    }

    export interface IPolicyCession {
        policyCessionGUID?: string;
        policyGUID: string;
        cessionActive: boolean;
        cessionDateTime: string;
        cessionDescription: string;
        creationDateTime?: Date;
    }

    export interface IPolicyRAR {
        policyRarId?: string;
        policyId: string;
        required: boolean;
        completed: boolean;
    }

    export interface IProductConfig {
        UID: string;
        name: string;
        displayName: string;
        category: string;
    }

    export interface IExclusion {
        ExclusionCode: string;
        CheckCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
    }

    export interface IIndividualExclusionSetAPI {
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
        Status: string;
        Identifier: string;
        FriendlyIdentifier: string;
        Exclusions: IExclusion[];
    }

    export interface IEvent {
        EventCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
        TierName: string;
        TierPercentage: number;
        SurvivalPeriod: number;
    }

    export interface IClaimSet {
        Identifier: string;
        StartDate?: Date;
        EndDate?: Date;
        Status: string;
        ClaimPatterns?: ClaimPattern;
        Event: Event;
        ProductSelected?: IProductSelected;
    }

    export interface IProductSelected {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface ClaimPattern {
        Identifier: string;
        StartDate: Date;
        Severity: number;
        Active: boolean;
    }

    export interface ClaimLine {
        Identifier: string;
        BenefitPatternGUID: string;
        PaymentStatus: string;
        CanPay: boolean;
        Amount: number;
        EffectiveDate: Date;
        MasterStatus: string;
    }

    export interface IPerson {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductSelectedAPI {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface ICoverInstanceAPI {
        Value: number;
        Premium: number;
        CoverStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        ProductSelectedAPI: IProductSelectedAPI;
    }

    export interface RoleAPI {
        RoleType: string;
        AssuredType: string;
        RoleStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        Person: IPerson;
        CoverInstanceAPIs: ICoverInstanceAPI[];
    }

    export interface ICoverInstanceAPI2 {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IEvent2 {
        EventCode: string;
        DisplayName: string;
        ConditionName: string;
        Description: string;
        TierName: string;
        TierPercentage: number;
        SurvivalPeriod: number;
    }

    export interface IEventSet {
        ShortCode: string;
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
        WaitingPeriod: number;
        Events: IEvent2[];
    }

    export interface IBCEFixed {
        BCOverallEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationTerm: number;
        BCEscalationCeaseAge: string;
    }

    export interface IBeforeClaimEscalation2 {
        BCE_Fixed: IBCEFixed;
        BCE_IndexLinked: IBCEIndexLinked;
    }

    export interface IBCEIndexLinked {
        BCAdditionalEscalation: number;
        Index: IIndex;
    }

    export interface IBenefitPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface IBenefitPattern {
        UID: string;
        PaymentFrequency: string;
        MetricLinkedBenefit: string;
        BeforeClaimEscalation: string;
        DeferredPeriod: number;
        CoverInForceCeaseAge: string;
        MaxCoverInForceTerm: string;
        BeforeClaimEscalation_2: IBeforeClaimEscalation2;
        //InClaimEscalation_2: IInClaimEscalation2;
        BenefitPatternSet: IBenefitPatternSet;
    }

    export interface IFixedIncreasePremium {
        EscalationFrequency: string;
        OverallEscalation: number;
        EscalationTerm: number;
        AdditionalEscalation: number;
    }

    export interface IPremiumPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface IIndex {
        Name: string;
        CheckName: string;
    }

    export interface IIndexLinkedPremium {
        EscalationFrequency: string;
        AdditionalEscalation: number;
        EscalationTerm: number;
        Index: IIndex;
    }

    export interface IPremiumPattern {
        UID: string;
        TaxPercentage: number;
        PremiumEscalationType: string;
        PaymentFrequency: string;
        CeaseAge: string;
        FixedIncreasePremium?: IFixedIncreasePremium;
        IndexLinkedPremium?: IIndexLinkedPremium;
        PremiumPatternSet: IPremiumPatternSet;
    }

    export interface IBespokeProductAPI {
        BespokeProductStatus: string;
        UnderwritingLoading: number;
        EventSet: IEventSet;
        BenefitPattern: IBenefitPattern;
        PremiumPattern: IPremiumPattern;
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IUnderwritingRule {
        ShortCode: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
    }

    export interface IConstraintConfig {
        MinBenefitAmount: number;
        MaxBenefitAmount: number;
        MinBenefitOverallEscalation: number;
        MaxBenefitOverallEscalation: number;
        MinBenefitAdditionalEscalation: number;
        MaxBenefitAdditionalEscalation: number;
        MinOfficePremium: number;
        MaxOfficePremium: number;
        MinPremiumOverallEscalation: number;
        MaxPremiumOverallEscalation: number;
        MinPremiumAdditionalEscalation: number;
        MaxPremiumAdditionalEscalation: number;
        MinAge: number;
        MaxAge: number;
        HIVRequirement: string;
        MinGrossIncome: number;
        Gender: string;
        MaxRecurringPayments: number;
        UnderwritingRules: IUnderwritingRule[];
        RatingFactors: IRatingFactor[];
    }

    export interface IProductConfig {
        ConstraintConfig: IConstraintConfig;
        UID: string;
        Name: string;
        DisplayName: string;
    }

    export interface IProduct {
        ProductConfig: IProductConfig;
    }

    export interface IProductSelectedAPI2 {
        Individual: boolean;
        Identifier: string;
        FriendlyIdentifier: string;
        CoverInstanceAPIs: ICoverInstanceAPI2[];
        BespokeProductAPIs: IBespokeProductAPI[];
        Product?: IProduct;
    }

    export interface ICoverInstanceAPI3 {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductPackageAPI {
        ReferenceNumber: string;
        InceptionDate: Date;
        ProductPackageStatus: string;
        RoleAPIs: RoleAPI[];
        ProductSelectedAPIs: IProductSelectedAPI2[];
        CoverInstanceAPIs: ICoverInstanceAPI3[];
    }

    export interface Quote {
        UID: string;
        QuoteNumber: string;
        InceptionDate: Date;
    }

    export interface NonIndividualExclusionSet {
        ShortCode: string;
        CheckCode: string;
        DisplayName: string;
        CategoryCode: string;
        CategoryName: string;
        Description: string;
    }

    export interface IUniqueProducts {
        productId: string;
        productName: string;
        PremiumAmount: number;
        CoverAmount: number;
    }

    export interface IPolicyModel {
        PolicyStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        IndividualExclusionSetAPIs?: IIndividualExclusionSetAPI[];
        ClaimSets: IClaimSet[];
        ProductPackageAPIs: IProductPackageAPI[];
        Quote: Quote;
        NonIndividualExclusionSets: NonIndividualExclusionSet[];
        productList?: IUniqueProducts[];
        SubAccount: {
            UID: string;
            SubAccountLongName: string;
        };
    }

    export interface IBeneficiary {
        AllocationPercentage: number;
        BeneficiaryAssuredType: string;
        Person: {
            Identifier: string
        };
    }

}


export declare module PersonModule {
    export interface IPersonBankingDetails {
        AccountHolderFirstName: string;
        AccountHolderIDNumber: string;
        AccountHolderInitials: string;
        AccountHolderLastName: string;
        AccountNumber: string;
        AccountType: string;
        Active: boolean;
        AmountLimitType: string;
        AmountRangeLimit: number;
        Bank: string;
        BranchCode: string;
        CDVChecked: boolean;
        DayOfMonthDebit: number;
    }

    export interface IPersonAddress {
        Address_1: string;
        Address_2: string;
        Address_3: string;
        Address_4: string;
        Identifier: string;
        PostalCode: string;
    }

    export interface IPersonAPI {
        AddressAPIs: Array<IPersonAddress>;
        BankingDetails: Array<IPersonBankingDetails>;
        IDNumber?: string;
        IdentificationType?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: string;
        Age?: number;
        ContactNumber?: string;
        Email?: string;
        PersonStatus?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor2 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor3 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IPolicyAPI {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductPackageAPI {
        PolicyAPIs: IPolicyAPI[];
    }

    export interface IProductConfig {
        UID: string;
        DisplayName: string;
        Category: string;
    }

    export interface IProduct {
        ProductConfig: IProductConfig;
    }

    export interface IProductSelectedAPI {
        Identifier: string;
        FriendlyIdentifier: string;
        ProductPackageAPI: IProductPackageAPI;
        Product: IProduct;
    }

    export interface ICoverInstanceAPI {
        Value: number;
        Premium: number;
        CoverStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        ProductSelectedAPI: IProductSelectedAPI;
    }

    export interface IRoleAPI {
        RoleType: string;
        AssuredType: string;
        RoleStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IBrokerModel {
        BrokerIDP_GUID: string;
        SupervisionIndicator: string;
        Qualifications: string;
        ExperienceStart: Date;
        PI_CoverIndicator: string;
        FI_CoverIndicator: string;
        ProductSpecificTraining: string;
        CPD_RequirementsMet: string;
        ActiveStartDate: Date;
        ProfessionID: string;
        PersonAPI: PersonAPI;
    }

    export interface IPersonModel {
        AdditionalContactNumber: string;
        AdditionalEmail: string;
        GrossIncome: number;
        PersonAPI: IPersonAPI;
        RatingFactor: IRatingFactor;
        RatingFactor_2: IRatingFactor2;
        RatingFactor_3: IRatingFactor3;
        CoverInstanceAPIs: ICoverInstanceAPI[];
        RoleAPIs: IRoleAPI[];
        EffectiveDate?: Date;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        SocioEconomicClass?: string;
        SmokingStatus?: string;
        Occupation?: string;
        Education?: string;
        IDP_GUID?: string;
    }

    export interface AddressAPI {
        Address_1?: string;
        Address_2?: string;
        Address_3?: string;
        Address_4?: string;
        PostalCode?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface DebitMandateDTO {
        IDNumber: string;
        Initials: string;
        Name: string;
        Surname: string;
        AccountNumber: string;
        Bank: string;
        BranchCode: string;
        AccountType: string;
        AmountLimitType: string;
        AmountRangeLimit: number;
        DayOfMonthDebit: number;
        CDVChecked: boolean;
        Active: boolean;
    }

    export interface PersonAPI {
        IDNumber?: string;
        PassportNumber?: string;
        RegistrationNumber?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: any;
        IdentificationType?: string;
        ContactNumber?: string;
        Email?: string;
        AddressAPIs?: AddressAPI[];
        DebitMandateDTOs?: DebitMandateDTO[];
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface IPersonInput {
        AdditionalContactNumber?: string;
        AdditionalEmail?: string;
        PersonAPI?: PersonAPI;
        EffectiveDate?: any;
        GrossIncome?: number;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        Education?: string;
        IDP_GUID?: string;
    }
}

export declare module QuirkQuestionsDictonaryModule {

    export interface Value {
        extId: string;
        value: string;
    }

    export interface IQuirkQuestionsDictonary {
        name: string;
        value: Value[];
    }

}


export interface SubmitAnswerAndGetUpdatedQuestions {
    sections?: GetQuestionariesModule.ISection;
    customerReferenceid?: string;
    policyReferenceId?: string;
    updatePolicyAnswers?: CreateOrUpdatePolicyInputModule.IUpdatePolicyOfApplicantWithAnswers;
}


export declare module PolicyModule {
    export interface IPolicyListResult {
        AcceptedDate: string;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyStatusName: string;
        Premium: number;
        QuestionnaireId: number;
        RedirectUrl: string;
    }

    export interface IGetAllPolicyList {
        ErrorDetails?: any;
        NumberOfPolicies: number;
        PolicyListResult: IPolicyListResult[];
    }
}

export interface IBankDetails {
    BankName?: string;
    BankBranchCode?: string;
    BankAccType?: string;
    BankAccHolderInitials?: string;
    BankAccHolderFirstName?: string;
    BankAccHolderLastName?: string;
    BankAccHolderIDNumber?: string;
    BankAccNumber?: string;
    BankPaymentMethod?: string;
    BankCollectionDate?: number;
    BankCheckCDV?: boolean;
    BankAccHolder?: string;
}


export interface CreateQuote extends IBankDetails {
    MainMember?: string;
    LifeAssured?: string;
    LifeAssuredAssuredType?: string;
    InceptionDate?: any;
    Broker?: any;
    QuoteStatus?: string;
}

export declare module BenificeryModule {
    export interface IBenificaryFormModel {
        title?: ITitleSalutation;
        firstName?: string;
        lastName?: string;
        idNumberString?: string;
        knownAs?: string;
        relatonShipToPolicyHolder?: RelationshipEnum;
        cellNumber?: string;
        emailAddress?: string;
        allocationPercentage?: string;
    }

    export interface ITitleSalutation {
        text?: string;
        value?: number;
    }


    export enum RelationshipEnum {
        Husband,
        Wife,
        Father,
        Mother
    }

    export interface ProductModel {
        name?: string;
        beneficiaryModel?: BenificeryModule.IBenificaryFormModel;
    }

    export interface IBeneficiaryForQuote {
        Beneficiary: string;
        AllocationPercentage: number;
        AssuredType: string;
    }
}

export declare module ProductModule {
    export interface ProductDetailSummary {
        productName: string;
        items: Array<ProductDetailsSummaryItem>;
    }

    export interface ProductDetailsSummaryItem {
        title: string;
        subDesc: string;
        pointsList: Array<{
            item: string;
            list: Array<string>;
        }>;
    }
}


export declare module ProductListModule {

    export interface GuaranteeConfig {
        GuaranteeType: string;
        GuaranteePeriod: number;
        MaximumAdHocIncreasePercentage: number;
        MaximumAdHocIncreaseAdjustor: number;
        GuaranteePeriodAdjustor: boolean;
    }

    export interface GuaranteedAssurability {
        AssurabilityType: string;
        Percentage: number;
        Term: number;
    }

    export interface ConstraintConfig {
        MinAge: number;
        MaxAge: number;
        HIVRequirement: string;
        MinGrossIncome: number;
        Gender: string;
        GuaranteeConfig: GuaranteeConfig;
        MinBenefitAmount: number;
        MaxBenefitAmount: number;
        MinBenefitOverallEscalation: number;
        MaxBenefitOverallEscalation: number;
        MinBenefitAdditionalEscalation: number;
        MaxBenefitAdditionalEscalation: number;
        MinOfficePremium: number;
        MaxOfficePremium: number;
        MinPremiumOverallEscalation: number;
        MaxPremiumOverallEscalation: number;
        MinPremiumAdditionalEscalation: number;
        MaxPremiumAdditionalEscalation: number;
        GuaranteedAssurabilitys: GuaranteedAssurability[];
    }

    export interface BenefitPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface PremiumPatternSet {
        UID: string;
        FriendlyIdentifier: string;
    }

    export interface EventSet {
        ShortCode: string;
        Description: string;
    }

    export interface ProductConfig {
        UID: string;
        Name: string;
    }

    export interface ProductRequirements2 {
        RequirementType: string;
        RiderPolicy: boolean;
        Accelerator: boolean;
        ProductConfig: ProductConfig;
    }

    export interface ProductConfig2 {
        UID: string;
        Name: string;
    }

    export interface ProductRequirement {
        ProductConfig_2: ProductConfig2;
    }

    export interface IProductListModel {
        UID: string;
        Name: string;
        ConstraintConfig: ConstraintConfig;
        BenefitPatternSets: BenefitPatternSet[];
        PremiumPatternSets: PremiumPatternSet[];
        EventSets: EventSet[];
        Category: string;
        DisplayName: string;
        ProductRequirements_2: ProductRequirements2[];
        ProductRequirements: ProductRequirement[];
        riderProducts?: IProductListModel[];
        isMainProduct?: boolean;
        isProductQuoted?: boolean;
        premiumAmount?: number;
        AssuredValue?: number;
        selected?: boolean;
        producQuotedId?: string;
    }

    export interface ISelectedProductDetails {
        product?: Product;
        productedQuotedId?: string;
        productName?: string;
        quoteId?: string;
        premiumAmount?: number;
        productId?: string;
        productCategory?: string;
        assuredValue?: number;
        riderProductDetails?: any;
        prdouctRef?: IProductListModel;
        riderProductRef?: IProductListModel[];
        isMainProduct?: boolean;
        bankingDetailsAdded?: boolean;
        benifiieryAdded?: boolean;
        beneficiaries?: BenficiaryModule.IBeneficiaryModel[];
        bankingDetails?: IBankDetails;
    }
}



export declare module RateTableModule {

    export interface IProductConfig {
        UID: string;
    }

    export interface IEventSet {
        ShortCode: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
        Description: string;
    }

    export interface IBenefitPattern {
        UID: string;
    }

    export interface IPremiumPattern {
        UID: string;
    }

    export interface IRateTableModel {
        UID: string;
        FriendlyIdentifier: string;
        BaseRate: number;
        ProductConfig: IProductConfig;
        EventSets: IEventSet[];
        RatingFactors: IRatingFactor[];
        BenefitPatterns: IBenefitPattern[];
        PremiumPatterns: IPremiumPattern[];
    }
}

export declare module BenefitModule {

    export interface IBCEFixed {
        BCOverallEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationTerm: number;
        BCEscalationCeaseAge: string;
    }

    export interface IIndex {
        Name: string;
    }

    export interface IBCEIndexLinked {
        BCAdditionalEscalation: number;
        BCEscalationFrequency: string;
        BCEscalationCeaseAge: string;
        Index: IIndex;
    }

    export interface IBCEReviewable {
        ReviewPeriod: string;
    }

    export interface IBeforeClaimEscalation2 {
        BCE_Fixed?: IBCEFixed;
        BCE_IndexLinked?: IBCEIndexLinked;
        BCE_Reviewable?: IBCEReviewable;
    }

    // tslint:disable-next-line: no-empty-interface
    export interface InClaimEscalation2 {
    }

    export interface IBenefitPattern {
        UID?: string;
        PaymentFrequency?: string;
        MetricLinkedBenefit?: string;
        BeforeClaimEscalation?: string;
        DeferredPeriod?: number;
        Deleted?: boolean;
        InClaimEscalation?: string;
        CoverInForceCeaseAge?: string;
        MaxCoverInForceTerm?: string;
        BeforeClaimEscalation_2?: IBeforeClaimEscalation2;
        InClaimEscalation_2?: any;
        selected?: boolean;
    }

    export interface IBenefitModel {
        Selected?: boolean;
        UID?: string;
        FriendlyIdentifier?: string;
        BenefitPatterns?: IBenefitPattern[];
    }

}



export declare module PremiumPatternModule {

    export interface PremiumPattern {
        UID?: string;
        TaxPercentage?: number;
        PremiumEscalationType?: string;
        PaymentFrequency?: string;
        CeaseAge?: string;
        FixedIncreasePremium?: IFixedIncreasePremium;
        IndexLinkedPremium?: IIndexLinkedPremium;
        selected?: boolean;
    }

    export interface IPremiumPatternModel {
        UID?: string;
        FriendlyIdentifier?: string;
        PremiumPatterns?: PremiumPattern[];
    }

    export interface IFixedIncreasePremium {
        EscalationFrequency: string;
        EscalationTerm: number;
        OverallEscalation: number;
    }

    export interface IIndexLinkedPremium {
        AdditionalEscalation: number;
        EscalationFrequency: string;
        EscalationTerm: number;
        Index: Index;
    }

    export interface Index {
        Name: string;
    }

    export interface IRateTableModel {
        BenefitPatterns: string[];
        PremiumPatterns: any[];
    }
}

export declare module BenficiaryModule {
    export interface IBeneficiaryModel {
        entityNumber?: string;
        productName?: string;
        salutation?: string;
        firstName?: string;
        lastName?: string;
        idNumber?: string;
        callingName?: string;
        relationshipToPolicyHolder?: string;
        phoneNumber?: string;
        email?: string;
        adminSystemGuid?: string;
        allocationPercentage?: number;
        isPrimary?: boolean;
    }
}

export module SocialEconomicModule {

    export interface ClassSocio {
        Salary: number;
        Class: string;
    }

    export class SocialEconomic {
        Education: number;
        Class: ClassSocio[];

        getClass(salary) {
            return this.Class.find(x => x.Salary === salary) ? this.Class.find(x => x.Salary === salary).Class : 'Class_1';
        }
    }
}

export declare module ConditionListModule {

    export interface IConditionOutPut {
        ErrorDetails?: any;
        Data: IDatum[];
    }

    export interface IDatum {
        Description: string;
        FloatingIndex: boolean;
        Id: number;
        Sequence: number;
        SetupTableId: number;
        Status: number;
        Synonyms: ISynonym[];
        Title: string;
    }


    export interface ISynonym {
        FloatingIndex: boolean;
        Id: number;
        Sequence: number;
        SetupTableId: number;
        Status: number;
        Title: string;
    }

}

export module PolicyStatusChangeModule {

    export interface PolicyListResult {
        AcceptedDate: string;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyStatusName: string;
        Premium: number;
        QuestionnaireId: number;
        RedirectUrl: string;
    }

    export interface PolicyStatusChange {
        ErrorDetails?: any;
        NumberOfPolicies: number;
        PolicyListResult: PolicyListResult[];
    }

}

export module SubmitQuestionnaireModule {

    export interface PolicyDetail {
        AcceptedDate: string;
        AcceptedStatus: number;
        ActivationDate: string;
        AnsweredQuestions?: Array<any>;
        CustomerId: number;
        CustomerReferenceId: string;
        ExternalReferenceId: string;
        PolicyId: number;
        PolicyNumber: number;
        Premium: number;
        PremiumExcelCalculatorName?: any;
        Products: any[];
        QuestionnaireId: number;
        RedirectUrl: string;
        References: any[];
        Status: number;
        SumAssured: number;
    }

    export interface PolicyReference {
        Id: number;
        Reference: string;
        ReferenceId: number;
        State?: number;
        Title: string;
        Type: number;
        TypeName: string;
    }

    export interface Premium {
        BasePremium: number;
        FinalPremium: number;
        PercentageLoading: number;
        ProfileId: number;
        ProfileName: string;
        UnitLoading: number;
    }

    export interface Product {
        DecisionName: string;
        Premium: number;
        Premiums: Premium[];
        ProductReferences: any[];
        ProductStatusReason: string;
        ProfileId: number;
        ProfileName: string;
        SumAssured: number;
    }

    export interface SubmitQuestionnaire {
        ErrorDetails?: any;
        AcceptedDate: string;
        AcceptedStatus: number;
        AcceptedStatusName: string;
        ActivationDate: string;
        CustomerId: number;
        ExternalReferenceId: string;
        PolicyDetail: PolicyDetail;
        PolicyId: number;
        PolicyReferences: PolicyReference[];
        PolicyStatusName: string;
        Premium: number;
        ProductNotes: any[];
        Products: Product[];
        QuestionAnswers?: Array<any>;
        QuestionnaireId: number;
        QuestionnaireStatusName: string;
        SectionDisclosures?: any;
        Status: number;
        SumAssured: number;
        VersionId: number;
        VersionName: string;
    }

}


export declare module PersonModule {

    export interface IPersonAPI {
        IDNumber?: string;
        IdentificationType?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: string;
        Age?: number;
        ContactNumber?: string;
        Email?: string;
        PersonStatus?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface IRatingFactor {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor2 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IRatingFactor3 {
        ShortCode: string;
        RatingFactorType: string;
        Description: string;
    }

    export interface IPolicyAPI {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductPackageAPI {
        PolicyAPIs: IPolicyAPI[];
    }

    export interface IProductConfig {
        UID: string;
        DisplayName: string;
        Category: string;
    }

    export interface IProduct {
        ProductConfig: IProductConfig;
    }

    export interface IProductSelectedAPI {
        Identifier: string;
        FriendlyIdentifier: string;
        ProductPackageAPI: IProductPackageAPI;
        Product: IProduct;
    }

    export interface ICoverInstanceAPI {
        Value: number;
        Premium: number;
        CoverStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
        ProductSelectedAPI: IProductSelectedAPI;
    }

    export interface IRoleAPI {
        RoleType: string;
        AssuredType: string;
        RoleStatus: string;
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IPersonModel {
        AdditionalContactNumber: string;
        AdditionalEmail: string;
        GrossIncome: number;
        PersonAPI: IPersonAPI;
        RatingFactor: IRatingFactor;
        RatingFactor_2: IRatingFactor2;
        RatingFactor_3: IRatingFactor3;
        CoverInstanceAPIs: ICoverInstanceAPI[];
        RoleAPIs: IRoleAPI[];
        EffectiveDate?: Date;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        SocioEconomicClass?: string;
        SmokingStatus?: string;
        Occupation?: string;
        Education?: string;
        IDP_GUID?: string;
    }

    export interface AddressAPI {
        Address_1?: string;
        Address_2?: string;
        Address_3?: string;
        Address_4?: string;
        PostalCode?: string;
        Identifier?: string;
        FriendlyIdentifier?: string;
    }

    export interface DebitMandateDTO {
        IDNumber: string;
        Initials: string;
        Name: string;
        Surname: string;
        AccountNumber: string;
        Bank: string;
        BranchCode: string;
        AccountType: string;
        AmountLimitType: string;
        AmountRangeLimit: number;
        DayOfMonthDebit: number;
        CDVChecked: boolean;
        Active: boolean;
    }

    export interface PersonAPI {
        IDNumber?: string;
        PassportNumber?: string;
        RegistrationNumber?: string;
        FirstName?: string;
        Initials?: string;
        Surname?: string;
        Gender?: string;
        DateOfBirth?: any;
        IdentificationType?: string;
        ContactNumber?: string;
        Email?: string;
        AddressAPIs?: AddressAPI[];
        DebitMandateDTOs?: DebitMandateDTO[];
    }

    export interface IPersonInput {
        AdditionalContactNumber?: string;
        AdditionalEmail?: string;
        PersonAPI?: PersonAPI;
        EffectiveDate?: any;
        GrossIncome?: number;
        MedicalSchemeOptionPUT?: string;
        RegionProvincePUT?: string;
        EmployerLocalOfficePUT?: string;
        SmokingStatusPUT?: string;
        OccupationCategoryPUT?: string;
        SocioEconomicClassPUT?: string;
        Education?: string;
        IDP_GUID?: string;
    }
}


export module RatingFactorModule {
    export class PersonRatingFactor {
        age: number;
        smoker: string;
        occupational: string;
        socials: string;
        gender: string;
        constructor(age: number,
            smoker: string,
            occupational: string,
            gender: string,
            socials: string) {
            this.age = age;
            this.smoker = smoker;
            this.occupational = occupational;
            this.gender = gender;
            this.socials = socials;
        }
    }

    export interface IIncompletePersonRatingFactor {
        withPersonMetaData(data: PersonModule.IPersonModel): IIncompletePersonRatingFactor;
        withMetadata(data: GetQuestionariesModule.IQuestion[]): IIncompletePersonRatingFactor;
        getAge(): IIncompletePersonRatingFactor;
        getSmoker(): IIncompletePersonRatingFactor;
        getOccupational(): IIncompletePersonRatingFactor;
        getGender(): IIncompletePersonRatingFactor;
        getSocials(): IIncompletePersonRatingFactor;
        build(): PersonRatingFactor;
    }

    export class PersonRatingFactorFactory implements IIncompletePersonRatingFactor {


        private age: number;
        private smoker: string;
        private occupational: string;
        private social: string;
        metaData: GetQuestionariesModule.IQuestion[];
        metaDataPerson: PersonModule.IPersonModel;
        gender: string;

        withPersonMetaData(data: PersonModule.IPersonModel): IIncompletePersonRatingFactor {
            this.metaDataPerson = data;
            return this;
        }

        withMetadata(data: GetQuestionariesModule.IQuestion[]): IIncompletePersonRatingFactor {
            this.metaData = data;
            return this;
        }


        getAge(): IIncompletePersonRatingFactor {
            this.age = this.metaDataPerson.PersonAPI.Age;
            return this;

        }

        private getAgeFromDate(dateString) {
            let today = new Date();
            let birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        getSmoker(): IIncompletePersonRatingFactor {
            this.smoker = this.metaDataPerson.SmokingStatus;
            return this;
        }
        getOccupational(): IIncompletePersonRatingFactor {
            this.occupational = this.metaDataPerson.Occupation;
            return this;
        }
        getGender(): IIncompletePersonRatingFactor {
            this.gender = this.metaDataPerson.PersonAPI.Gender;
            return this;
        }

        getSocials(): IIncompletePersonRatingFactor {
            this.social = this.metaDataPerson.SocioEconomicClass;
            return this;
        }
        build(): PersonRatingFactor {
            return new PersonRatingFactor(this.age, this.smoker, this.occupational, this.gender, this.social);
        }
    }
}


export declare module QuoteModule {
    export interface ICloneQuote {
        quoteUids: Array<string>;
        personUid: string;
    }

    export interface IFilterOutClonedQuotes {
        quoteUids: Array<string>;
    }

    export interface INeedsAssessmentSumAssuredLevel {
        quircCategory: string;
        mendixCategory: string;
        excluded: string;
        noNeedsAssessmentSumAssured: number;
        fullNeedsAssessmentSumAssured: number;
        partialNeedsAssessmentSumAssured: number;
    }

    export interface IQuoteDetails {
        BankAccHolderLastName: string;
        BankAccHolderInitials: string;
        UID: string;
        QuoteNumber: string;
        QuoteStatus: string;
        LastStatusChangeDate: string;
        InceptionDate: string;
        LifeAssuredAssuredType: string;
        CalculatedPremium: number;
        BankName: string;
        BankBranchCode: string;
        BankAccHolder: string;
        BankAccNumber: string;
        BankCheckCDV: boolean;
        BankAccType: string;
        BankPaymentMethod: string;
        BankCollectionDate: number;
        ProductQuoteds?: IProductQuoted[];
        Person_MainMember: IPerson;
        Person_LifeAssured: IPerson;
        Person_Broker: IPerson;
        IncrementQuote: boolean;
        ReplacementQuote: boolean;
        RollbackQuote: boolean;
    }

    export interface IPerson {
        Identifier: string;
        FriendlyIdentifier: string;
    }

    export interface IProductQuoted {
        IsMainProduct: boolean;
        AssuredValue: number;
        PremiumValue: number;
        ProductConfig: IProductConfig;
        EventSet: IEventSet;
        BenefitPattern: IBenefitPattern;
        PremiumPattern: IBenefitPattern;
    }

    export interface IBenefitPattern {
        UID: string;
    }

    export interface IEventSet {
        ShortCode: string;
    }

    export interface IProductConfig {
        UID: string;
        Name: string;
    }
}

export interface IIncrementalQuote {
    GUID: string;
    CalculatedPremium: number;
}


export class BenefitPattern {
    name: string;
    value: BenefitModule.IBenefitPattern;
}

export class PremiumPattern {
    name: string;
    value: PremiumPatternModule.PremiumPattern;
}

export class ProductQuote {
    Product: string;
    BenefitPattern: string;
    PremiumPattern: string;
    EventSet: string;
    AssuredValue: number;
    MultiplicativeUnderwritingLoading: number;
    AdditiveUnderwritingLoading: number;
}


export class RiderProductQuote extends ProductQuote {
    MainProduct: string;
}

export interface IProductAndBeneficiaryDetails {
    quoteId: string;
    productId: string;
    productName: string;
    beneficiaryDetails?: Array<IBeneficiaryDetailsPolcy>;

}
export interface IBeneficiaryDetailsPolcy {
    beneficiaryId: string;
    name: string;
    productId: string;
    quoteId: string;
    percentage: number;
    beneficiaryModel?: BenficiaryModule.IBeneficiaryModel;
}

export interface PersonMainMember {
    GUID: string;
    Identifier: string;
}

export interface GetAllQuotes {
    QuoteUID: string;
    QuoteNumber: string;
    QuoteStatus: string;
    PersonRole: string;
    DateCreated: Date;
    Person_MainMember: PersonMainMember;
    PolicyNumber: string;
    BrokerId: string;
    UnderwritingStatus: number;
    IsApproved: boolean;
    CoverReason?: string;
    BusinessAssuranceType?: string;
    PolicyHolderIsInsuredLife?: string;
}

export interface ISubmittedQuoteDto {
    quoteGuid: string;
    brokerAdminGuid: string;
    assignedBrokerGuid: string;
    submittedDateTime: Date;
}

export interface BrokerConsultantMapping {
    brokerAdminId: string;
    brokerIdpId: string;
    brokerageShortCode: string;
    familyName: string;
    givenName: string;
    email: string;
}

export interface CommissionPattern {
    brokerIdpGuid: string;
    brokerAdminGuid: string;
    brokerageShortName: string;
    commissionType: string;
    percentageOfMaxCommission: number;
    primaryCommissionPaymentMonths: string;
    primaryCommissionPaymentPercentages: string;
    secondaryCommissionPaymentMonths: string;
    secondaryCommissionPaymentPercentages: string;
    recurringBaseCommissionPercentage: number | null;
    recurringCommissionAges: string;
    recurrintCommissionPercentages: string;
    isActive: boolean;
    informationDate: Date;
}

export declare module CommissionModule {
    export interface BrokerCommission {
        commissionValues: Array<CommissionValue>;
        primaryCommission: Array<CommissionEntry>;
        secondaryCommission: Array<CommissionEntry>;
        commissionPattern: CommissionPattern;
    }

    export interface CommissionValue {
        actualElevateAsAndWhenCommission: number;
        actualElevatePrimaryCommission?: null;
        brokerIdpGuid: string;
        commissionAmount: number;
        commissionPaymentDate: string;
        percentageOfActualElevateCommissionPaid?: null;
        premiumAmountUponWhichCommissionIsBased: number;
        quoteGuid: string;
        commissionPaymentDateIndex: number;
    }

    export interface CommissionEntry {
        commission: number;
        month: number;
        percentage: number;
        premium: number;
    }
}

export declare module RateTableEntriesFlatModule {

    export interface RateTableEntries {
        UID: string;
        BaseRate: number;
        ProductOfFactors: number;
        BenefitPatternGUID: string;
        PremiumPatternGUID: string;
        RatingFactor_SumAssuredLimit_Description: string;
    }

}
export declare module GetMaxSumAssuredModule {
    export interface ExistingCover {
        existingElevate: number;
        existingOther: number;
        currentElevate: number;
        productSubCategory: string;
        mainProductCheck: boolean;
    }

    export interface GetMaxSumAssuredInput {
        age: number;
        grossMonthlyIncome: number;
        netMonthlyIncome: number;
        isSelfEmployed: boolean;
        productCategory?: any;
        needsAnalysis?: any;
        houseHoldIncome: number;
        employmentCategory?: any;
        policyReason?: any;
        isAccelerator: boolean;
        mainProductCurrentSumAssured: number | null;
        existingCover: ExistingCover;
        maxBenefitAmount: number;
    }
}

export declare module BeneficieryOutputModule {

    export interface BeneficieryOutput {
        Beneficiary: string;
        AllocationPercentage: number;
        AssuredType: string;
    }

}

export declare module RatingFactorInputResponseModule {

    export interface RatingFactor {
        RatingFactorType: string;
        Description: string;
    }

    export interface RatingFactorInputResponse {
        ProductGUID: string;
        RatingFactors: RatingFactor[];
    }
}

export class ProductSummary {
    categoryName: string;
    productUID?: string;
    products: Product[];
}

export interface IQuircAnalysisAfterSubmitting {
    QuircProfileName: string;
    decisionName: string;
    sumUnitLoading: number;
    sumPercentageLoading: number;
    startDate?: string;
    EndDate?: string;
    MendixGenerationName?: string;
    MendixProductUID?: string;
    MendixProductName?: string;
    MendixProductCategory?: string;
    MendixProductDisplayName?: string;
    QuircVersionName?: string;
    AggregationCategory: string;
    productReferences?: any[];
}



export class SynopsisDetails {
    section?: string;
    id?: string[] = new Array<string>();
    Value?: string[] = new Array<string>();
    beneficiaryFirstName?: string;
    beneficiaryLastName?: string;
    benefitPercentage?: number;
    beneficiaryId?: string;
    accountNumber?: string;
    debitCollectionDay?: number;
    synopsisDetails?: SynopsisDetails[];
}

export interface IdentityExpressUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface UserName {
    id: string;
    firstName: string;
    lastName: string;
    activeEndDate?: string;
    isDeleted?: boolean;
}

export interface UserMetadata {
    givenName: string;
    familyName: string;
    idNumber: string;
    birthdate: string;
    gender: string;
    phoneNumber: string;
    email: string;
    tenantName: string;
    medicalSchemeName: string;
    medicalSchemeMembershipNumber: string;
    allowFinancial: string;
    allowHealthcare: string;
    allowWearable: string;
    allowCredit: string;
    marketingMaterialAccepted: string;
    education: string;
    grossIncome: string;
    occupation: string;
    occupationCategory: string;
    smokingStatus: string;
    socioEconomicClass: string;
    physicalAddressLine1: string;
    physicalAddressLine2: string;
    physicalAddressProvence: string;
    physicalAddressSuburb: string;
    physicalAddressCity: string;
    physicalAddressCode: string;
    maritalStatus: string;
    needsAssessment: string;
    needsAssessmentDate: string;
    proofOfIncome: string;
    proofOfIncomeDate: string;
    creditCheck: string;
    creditCheckDate: string;
    netIncome: string;
    needsAssessmentLifeCover: string;
    needsAssessmentSevereIllnessCover: string;
    needsAssessmentIncomeProtection: string;
    needsAssessmentDisabilityImpairmentCover: string;
    confirmedNetIncome: string;
    confirmedGrossIncome: string;
    brokerageShortCode: string;
    brokerAdminId?: string;
    brokerCpdRequirements: string;
    brokerFiCover: string;
    brokerPiCover: string;
    brokerSupervision: string;
    brokerProductSpecificTraining: string;
    brokerQualifications: string;
    brokerActiveStartDate: string;
    brokerActiveEndDate: string;
    brokerExperienceStartDate: string;
    medicalSchemeId: string;
    medicalSchemeAdultDependentCount: string;
    medicalSchemeChildDependentCount: string;
    replaceExistingCover: string;
    manualRefer: string;
    isDeleted?: boolean;
}

export const defaultOccupationClass = (occupationClass: string): string => {
    try {
        if (occupationClass) {
            const level = +(occupationClass.substring(occupationClass.length - 1));

            if (level > 4) {
                return 'Class_4';
            }

            return occupationClass;
        }
    } catch (e) {
        return occupationClass;
    }
};

export const convertUserMetadataToUserDetail = (metadata: UserMetadata, userId: string): AnonymousUserDetail => {
    const convertStringToBool = (str: string): boolean | null => {
        if (!str) {
            return null;
        }

        return str === 'true';
    };

    const convertStringToInt = (str: string): number | null => {
        if (!str) {
            return null;
        }

        return +str;
    };

    const convertStringToDate = (str: string): moment.Moment | null => {
        if (!str) {
            return null;
        }

        return moment(str, 'DD/MM/YYYY hh:mm:ss A');
    };

    return {
        cellNumber: metadata.phoneNumber,
        dob: new Date(metadata.birthdate),
        email: metadata.email,
        gender: metadata.gender,
        identityNumber: metadata.idNumber,
        name: metadata.givenName,
        surname: metadata.familyName,
        addressLine1: metadata.physicalAddressLine1,
        addressLine2: metadata.physicalAddressLine2,
        age: metadata.idNumber === '0000000000000' ? 0 : metadata.idNumber.getAgeByIdNumber(),
        city: metadata.physicalAddressCity,
        education: metadata.education,
        grossSalary: +metadata.grossIncome,
        isQuoteImprovedForUser: false,
        medicalSchemeName: metadata.medicalSchemeName,
        occupation: metadata.occupation,
        occupationCategory: metadata.occupationCategory,
        postalCode: metadata.physicalAddressCode,
        resident: true,
        smokingStatus: metadata.smokingStatus,
        socialEconomic: metadata.socioEconomicClass,
        sub: userId,
        suburb: metadata.physicalAddressSuburb,
        summAssured: 0,
        maritalStatus: metadata.maritalStatus,
        creditCheck: convertStringToBool(metadata.creditCheck),
        creditCheckDate: convertStringToDate(metadata.creditCheckDate),
        needsAssessment: convertStringToBool(metadata.needsAssessment),
        needsAssessmentDate: convertStringToDate(metadata.needsAssessmentDate),
        proofOfIncome: convertStringToBool(metadata.proofOfIncome),
        proofOfIncomeDate: convertStringToDate(metadata.proofOfIncomeDate),
        netIncome: convertStringToInt(metadata.netIncome),
        needsAssessmentDisabilityImpairmentCover: convertStringToInt(metadata.needsAssessmentDisabilityImpairmentCover),
        needsAssessmentIncomeProtection: convertStringToInt(metadata.needsAssessmentIncomeProtection),
        needsAssessmentLifeCover: convertStringToInt(metadata.needsAssessmentLifeCover),
        needsAssessmentSevereIllnessCover: convertStringToInt(metadata.needsAssessmentSevereIllnessCover),
        confirmedNetIncome: convertStringToInt(metadata.confirmedNetIncome),
        confirmedGrossIncome: convertStringToInt(metadata.confirmedGrossIncome),
        medicalSchemeAdultDependentCount: metadata.medicalSchemeAdultDependentCount,
        medicalSchemeChildDependentCount: metadata.medicalSchemeChildDependentCount,
        medicalSchemeId: metadata.medicalSchemeId,
        replaceExistingCover: convertStringToBool(metadata.replaceExistingCover),
        manualRefer: convertStringToBool(metadata.manualRefer),
    };
};

export interface IAnonymousUserDetail {
    name: string;
    surname: string;
    gender: string;
    dob: Date;
    cellNumber: string;
    email: string;
}

export class AnonymousUserDetail implements IAnonymousUserDetail {
    name: string;
    surname: string;
    gender: string;
    dob: Date;
    cellNumber: string;
    email: string;
    identityNumber: string;
    sub?: string;
    resident?: boolean;
    socialEconomic?: string;
    grossSalary?: number;
    summAssured?: number;
    isQuoteImprovedForUser?: boolean;
    education?: string;
    medicalSchemeName?: string;
    occupation?: string;
    occupationCategory?: string;
    smokingStatus?: string;
    age?: number;
    addressLine1?: string;
    addressLine2?: string;
    suburb?: string;
    city?: string;
    postalCode?: string;
    maritalStatus?: string;
    netIncome?: number;
    proofOfIncome?: boolean | null;
    proofOfIncomeDate?: moment.Moment | null;
    creditCheck?: boolean | null;
    creditCheckDate?: moment.Moment | null;
    needsAssessment?: boolean | null;
    needsAssessmentDate?: moment.Moment | null;
    needsAssessmentLifeCover?: number | null;
    needsAssessmentSevereIllnessCover?: number | null;
    needsAssessmentIncomeProtection?: number | null;
    needsAssessmentDisabilityImpairmentCover?: number | null;
    confirmedNetIncome?: number | null;
    confirmedGrossIncome?: number | null;
    medicalSchemeId: string;
    medicalSchemeAdultDependentCount: string;
    medicalSchemeChildDependentCount: string;
    replaceExistingCover: boolean | null;
    manualRefer: boolean;
}

export interface IProductInformationList {
    productId: string;
    productName: string;
    premiumInstallment: number;
    coverAmount: number;
    benefitIncreaseIndex: string;
    benefitIncreases: string;
    benefitTerm: string;
    premiumIncreaseIndex: string;
    premiumIncreases: string;
    premiumTerm: string;
}

export interface IBeneficiaryList {
    beneficiaryId: string;
    productId: string;
    productName: string;
    beneficiaryName: string;
    coveredAmount: number;
    beneficiaryPercentage: number;
}

export interface AccountBalance {
    ammountUnsigned: number;
    dr_cr: string;
    amountSigned: number;
    amountRepresentation: string;
}

export interface AccountTransaction {
    TransactionUid: string;
    Amount: number;
    DebitCreditType: string;
    TransactionDate: Date;
    ProcessedDateTime: Date;
    Description: string;
    ContrAccountName: string;
}

export interface Bank {
    BankName: string;
    UniversalBranchCode: string;
    BankLookupName: string;
}

class NeedsAssessmentType {
    assessmentType: string;
    constructor(type: string) {
        this.assessmentType = type;
    }
}

export class MedicalInsuranceAssessment extends NeedsAssessmentType {
    constructor() {
        super(AppConsts.AssessmentType.MeidcalInsuranceAssessment);
    }

    private _childDependents = 0;
    public get childDependents(): number {
        return this._childDependents;
    }
    public set childDependents(value: number) {
        this._childDependents = value;
    }

    private _adultDependents = 0;
    public get adultDependents(): number {
        return this._adultDependents;
    }
    public set adultDependents(value: number) {
        this._adultDependents = value;
    }

    private _currentContribution: number;
    public get currentContribution(): number {
        return this._currentContribution;
    }
    public set currentContribution(value: number) {
        this._currentContribution = value;
    }

    private _userSetContribution: number;
    public get userSetContribution(): number {
        return this._userSetContribution;
    }
    public set userSetContribution(value: number) {
        this._userSetContribution = value;
    }

    private _schemeName: string;
    public get schemeName(): string {
        return this._schemeName;
    }
    public set schemeName(value: string) {
        this._schemeName = value;
    }

    private _schemeOptionName: string;
    public get schemeOptionName(): string {
        return this._schemeOptionName;
    }
    public set schemeOptionName(value: string) {
        this._schemeOptionName = value;
    }

    private _name: string;
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    private _userId: string;
    public get userId(): string {
        return this._userId;
    }
    public set userId(value: string) {
        this._userId = value;
    }
}

export declare module DbMedicalAidInsuranceModule {

    export interface IDbOption {
        optionName: string;
        incomeOptions: IDbOptionIncomeBand[];
    }

    export interface IDbOptionIncomeBand {
        incomeBand: string;
        mainMemberContributionRate: number;
        adultDependantContributionRate: number;
        childDependantContributionRate: number;
    }

    export interface IDbScheme {
        schemeName: string;
        options: IDbOption[];
    }

    export interface IFlatDbScheme {
        schemeName: string;
        schemeOption: string;
        incomeBand: string;
    }
}

export class CoverProposalBroker implements UserName {
    constructor(brokerId: string, firstName: string, lastName: string, isDeleted?: boolean, activeEndDate?: string ) {
        this.id = brokerId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.isDeleted = isDeleted;
        this.activeEndDate = activeEndDate;
        this.coverProposals = [];
        this.filteredCoverProposals = [];
        this.paginatedCoverProposals = [];

        this.idFormControl = new FormControl('');
        this.nameControl = new FormControl('');
    }

    id: string;
    firstName: string;
    lastName: string;
    isDeleted?: boolean;
    activeEndDate?: string;

    coverProposals: Array<string>;
    filteredCoverProposals: Array<string>;
    paginatedCoverProposals: Array<string>;

    idFormControl: FormControl;
    nameControl: FormControl;

    component: CoverProposalsTableComponent;
}


export interface IClientVerification {
    isVerified: boolean;
    requestedAt: Date;
    verifiedAt?: Date;
    expiresAt?: Date;
}

export interface IClientVerificationResponse {
    isVerified: boolean;
    verificationStatus: VerificationStatusType;
    verifiedAt?: Date;
}


export enum VerificationStatusType {
    None = 0,
    AwaitingCode = 1,
    CodeExpired = 2,
    Verified = 3,
    InvalidVerificationCode = 4,
    Expired = 5,
    MissingVerificationRequest = 6,
    VerificationUpdateFailed = 7
}


export interface IUserAdditionalDisclosure {
    disclosureGroupId: string;
    disclosureType: string;
    disclosureValue: string;
    userId: string;
}


export interface IUserUpdateAdditionalDisclosure {
    index: number;
    disclosureGroupId?: string;
    disclosureType: string;
    disclosureValue: string;
}


export interface IDeleteUserGroupAdditionalDisclosure {
    disclosureGroupId: string;
}


export interface IUpdateAdditionalDisclosure {
    accessToken: string;
    userId: string;
    addOrUpdateDisclosures: IUserUpdateAdditionalDisclosure[];
    deleteDisclosures: IDeleteUserGroupAdditionalDisclosure[];
}



