import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { ILeads } from 'app/main/my-data/client-list/leads/leads-modal.component';

import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, of, Subject } from 'rxjs';
import { ApiException, API_BASE_URL } from '../../../shared/service-proxies/service-proxies';
import { BrokerConsultantMapping, GetAllQuotes, QuoteModule } from '../models/elevate-data-models';
import { AppConsts } from '../../../shared/AppConsts';
import { IdentityAuthProviderService } from '../common/auth/identity-auth-provider.service';
import { ProductService } from './product.service';

export interface IProductInformation {
    productcategory: string | undefined;
    sumassured: number;
}

export class ProductInformationOutput implements IProductInformation {
    productcategory!: string | undefined;
    sumassured!: number;

    constructor(data?: IProductInformation) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): ProductInformationOutput {
        data = typeof data === 'object' ? data : {};
        let result = new ProductInformationOutput();
        result.init(data);
        return result;
    }

    init(_data?: any) {
        if (_data) {
            this.productcategory = _data['productcategory'];
            this.sumassured = _data['sumassured'];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['productcategory'] = this.productcategory;
        data['sumassured'] = this.sumassured;
        return data;
    }
}

export class LeadsOutput implements ILeads {
    email: string;
    firstname: string;
    surname: string;
    leadid: string;
    phone: string;
    productInformation: ProductInformationOutput[];

    constructor(data?: ILeads) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): LeadsOutput {
        data = typeof data === 'object' ? data : {};
        let result = new LeadsOutput();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.email = data['email'];
            this.firstname = data['firstname'];
            this.surname = data['surname'];
            this.leadid = data['leadid'];
            this.phone = data['phone'];
            if (data['productInformation'] && data['productInformation'].constructor === Array) {
                this.productInformation = [];
                for (let item of data['productInformation']) {
                    this.productInformation.push(ProductInformationOutput.fromJS(item));
                }
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class LeadService {
    quotes: Array<GetAllQuotes>;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    public cache = new Map();
    isCloningQuotes: boolean;
    cloneSubject = new Subject<Array<GetAllQuotes>>();

    constructor(
        private http: HttpClient,
        private _authService: IdentityAuthProviderService,
        private _productService: ProductService,
        @Inject(API_BASE_URL) private appServiceBaseUrl
    ) {
    }

    private readonly baseUrl = abp.setting.values[AppConsts.KeyVaultSecrets.apimElevateAccpendpoint];

    onCloneDone(): Observable<Array<GetAllQuotes>> {
        return this.cloneSubject.asObservable();
    }

    cloneQuotesWithSubjectNotification(cloneQuotes: QuoteModule.ICloneQuote): void {
        this.isCloningQuotes = true;

        this._productService.cloneQuote(cloneQuotes).subscribe(
            result => {
                this.isCloningQuotes = false;
                this.cloneSubject.next(result);
            },
            (err) => {
                console.error(err);
                this.cloneSubject.next(null);
            });
    }

    getUnAcceptedQuotes(brokerGuid: string, underWritingStatus?: number) {
        return this._productService.getQuoteOnAPersonBasedOnRole(brokerGuid, 'Broker', underWritingStatus).pipe(map(res => {
            this.quotes = res;
            return res.filter(x => x.Person_MainMember && x.QuoteStatus === AppConsts.QuoteStatus.Not_Accepted).filter((element, index, array) =>
                index === array.findIndex((data) =>
                    data.Person_MainMember.GUID === element.Person_MainMember.GUID)
            ).sort(function (a, b) {
                return (b.DateCreated.valueOf() - a.DateCreated.valueOf());
            });
        }));
    }

    getAcceptedQuotes(brokerGuid: string) {
        return this._productService.getQuoteOnAPersonBasedOnRole(brokerGuid, 'Broker').pipe(map(res => {
            return res.filter(x => x.QuoteStatus === AppConsts.QuoteStatus.Accepted);
        }));
    }

    getConsultantsForBroker(brokerId: string, accessToken: string): Observable<Array<BrokerConsultantMapping>> {
        let url = `${this.appServiceBaseUrl}/api/services/app/BrokerConsultant/GetConsultantsForBroker?brokerId=${brokerId}&accessToken=${accessToken}`;

        return this.http.get<any>(url, {
            headers: new HttpHeaders({
                'Accept': 'application/json'
            })
        }).pipe(map(res => res.result));
    }

    getBrokersForConsultant(consultantId: String, accessToken: String, getCached: boolean) {
        let url = `${this.appServiceBaseUrl}/api/services/app/BrokerConsultant/GetBrokersForConsultant?consultantId=${consultantId}&accessToken=${accessToken}`;

        if (getCached) {
            const brokers = this.cache.get(url);

            if (brokers) {
                return of(brokers);
            }
        }

        return this.http.get<any>(url, {
            headers: new HttpHeaders({
                'Accept': 'application/json'
            })
        }).pipe(map((res: ILeads[]) => {
            this.cache.set(url, res);
            return res;
        }));
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    if (result !== null && result !== undefined) {
        return _observableThrow(result);
    } else {
        return _observableThrow(new ApiException(message, status, response, headers, null));
    }
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next('');
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}


