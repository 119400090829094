export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    static ENV: string;

    static readonly Medshield = 'Medshield Medical Scheme';

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly ClaimTypes = {
        physical_address_provence: 'physical_address_provence',
        nickname: 'nickname',
        Compuscan_ContactNo3: 'Compuscan_ContactNo3',
        ui_MyDocuments: 'ui_MyDocuments',
        Compuscan_LastName: 'Compuscan_LastName',
        Compuscan_DateOfDeceased: 'Compuscan_DateOfDeceased',
        Compuscan_Employer4: 'Compuscan_Employer4',
        broker_qualifications: 'broker_qualifications',
        ui_MyResourceCentre: 'ui_MyResourceCentre',
        ui_MakeClaim: 'ui_MakeClaim',
        locale: 'locale',
        gender_selectable: 'gender_selectable',
        Compuscan_Address3: 'Compuscan_Address3',
        broker_product_specific_training: 'broker_product_specific_training',
        Compuscan_FirstName: 'Compuscan_FirstName',
        FullPortalAccess: 'FullPortalAccess',
        zoneinfo: 'zoneinfo',
        ui_MyNeedsCalculator: 'ui_MyNeedsCalculator',
        allow_credit: 'allow_credit',
        broker_active_start_date: 'broker_active_start_date',
        ui_MyCommandCentre: 'ui_MyCommandCentre',
        preferred_username: 'preferred_username',
        marketing_material_accepted: 'marketing_material_accepted',
        Compuscan_Employer2: 'Compuscan_Employer2',
        ui_AddOnBenefits: 'ui_AddOnBenefits',
        is_vaccinated: 'is_vaccinated',
        broker_cpd_requirements: 'broker_cpd_requirements',
        Compuscan_ContactNo4: 'Compuscan_ContactNo4',
        ui_MyPolicies: 'ui_MyPolicies',
        Compuscan_Employer1: 'Compuscan_Employer1',
        allow_healthcare: 'allow_healthcare',
        broker_profession_id: 'broker_profession_id',
        Compuscan_DateOfMarriage: 'Compuscan_DateOfMarriage',
        smoking_status: 'smoking_status',
        ui_MyRewards: 'ui_MyRewards',
        email: 'email',
        occupation_category: 'occupation_category',
        gender: 'gender',
        terms_conditions_accepted: 'terms_conditions_accepted',
        ui_Beneficiaries: 'ui_Beneficiaries',
        ui_NewPolicy: 'ui_NewPolicy',
        tenant_name: 'tenant_name',
        socio_economic_class: 'socio_economic_class',
        physical_address_country: 'physical_address_country',
        allow_financial: 'allow_financial',
        Compuscan_MaritalStatus: 'Compuscan_MaritalStatus',
        website: 'website',
        ui_MyWearables: 'ui_MyWearables',
        broker_pi_cover: 'broker_pi_cover',
        Compuscan_IdVerified: 'Compuscan_IdVerified',
        broker_experience_start_date: 'broker_experience_start_date',
        role: 'role',
        broker_fi_cover: 'broker_fi_cover',
        phone_number_verified: 'phone_number_verified',
        employer_name: 'employer_name',
        middle_name: 'middle_name',
        Compuscan_Address4: 'Compuscan_Address4',
        education: 'education',
        physical_address_city: 'physical_address_city',
        name: 'name',
        id_number_verified: 'id_number_verified',
        ui_MyBillingInfo: 'ui_MyBillingInfo',
        ui_ElevationFund: 'ui_ElevationFund',
        Compuscan_Address2: 'Compuscan_Address2',
        physical_address_line_2: 'physical_address_line_2',
        Compuscan_ContactNo2: 'Compuscan_ContactNo2',
        allow_finance: 'allow_finance',
        profile: 'profile',
        occupation: 'occupation',
        sub: 'sub',
        ui_MySupport: 'ui_MySupport',
        birthdate: 'birthdate',
        Compuscan_Employer5: 'Compuscan_Employer5',
        ui_MyData: 'ui_MyData',
        picture: 'picture',
        marital_status: 'marital_status',
        automatic_account_registration_credentials_sent: 'automatic_account_registration_credentials_sent',
        family_name: 'family_name',
        city: 'city',
        Compuscan_IdPhoto: 'Compuscan_IdPhoto',
        Compuscan_Address1: 'Compuscan_Address1',
        BrokerageUID: 'BrokerageUID',
        allow_wearable: 'allow_wearable',
        physical_address_code: 'physical_address_code',
        broker_active_end_date: 'broker_active_end_date',
        automatic_account_registration: 'automatic_account_registration',
        ui_MyFinance: 'ui_MyFinance',
        medical_scheme_name: 'medical_scheme_name',
        Compuscan_ContactNo5: 'Compuscan_ContactNo5',
        ui_EditPolicy: 'ui_EditPolicy',
        broker_brokerage_short_code: 'broker_brokerage_short_code',
        email_verified: 'email_verified',
        id_number: 'id_number',
        ui_MyGoals: 'ui_MyGoals',
        gross_income: 'gross_income',
        address: 'address',
        medical_scheme_membership_number: 'medical_scheme_membership_number',
        ui_MyHealthcare: 'ui_MyHealthcare',
        Compuscan_Employer3: 'Compuscan_Employer3',
        physical_address_line_1: 'physical_address_line_1',
        Compuscan_Address5: 'Compuscan_Address5',
        Compuscan_Deceased: 'Compuscan_Deceased',
        ui_BillingInfo: 'ui_BillingInfo',
        ui_OtherRewards: 'ui_OtherRewards',
        ui_MyCredit: 'ui_MyCredit',
        Compuscan_ContactNo1: 'Compuscan_ContactNo1',
        ui_MyNews: 'ui_MyNews',
        broker_supervision: 'broker_supervision',
        given_name: 'given_name',
        medical_scheme_option: 'medical_scheme_option',
        medical_scheme_membership_number_verified: 'medical_scheme_membership_number_verified',
        phone_number: 'phone_number',
        physical_address_suburb: 'physical_address_suburb',
        needs_assessment: 'needs_assessment',
        needs_assessment_date: 'needs_assessment_date',
        credit_check: 'credit_check',
        credit_check_date: 'credit_check_date',
        proof_of_income: 'proof_of_income',
        proof_of_income_date: 'proof_of_income_date',
        net_income: 'net_income',
        needs_assessment_life_cover: 'needs_assessment_life_cover',
        needs_assessment_income_protection: 'needs_assessment_income_protection',
        needs_assessment_severe_illness_cover: 'needs_assessment_severe_illness_cover',
        needs_assessment_disability_impairment_cover: 'needs_assessment_disability_impairment_cover',
        replace_existing_cover: 'replace_existing_cover',
        replace_existing_cover_date: 'replace_existing_cover_date',

        kept_existing_life_cover: 'kept_existing_life_cover',
        kept_existing_life_cover_date: 'kept_existing_life_cover_date',
        kept_existing_income_protection: 'kept_existing_income_protection',
        kept_existing_income_protection_date: 'kept_existing_income_protection_date',
        kept_existing_disability_impairment_cover: 'kept_existing_disability_impairment_cover',
        kept_existing_disability_impairment_cover_date: 'kept_existing_disability_impairment_cover_date',
        kept_existing_severe_illness_cover: 'kept_existing_severe_illness_cover',
        kept_existing_severe_illness_cover_date: 'kept_existing_severe_illness_cover_date',

        application_verified: 'application_verified',
        application_verified_date: 'application_verified_date',
    };

    static readonly configProducts = {
        Disability: 'Principal Disability Protection',
        Life: 'Principal Life Protection',
        SevereIllnessAccelerator: 'Principal Severe Illness Protection - Accelerator',
        FullTierBooster: 'Principal Full Tier Booster',
        SevereIllness: 'Principal Severe Illness Protection',
        SelfEmployedEnhancer: 'Principal Self-Employed Enhancer',
        AlternateSelfEmployedEnhancer: 'Principal Self Employed Enhancer',
        OwnOccupationBooster: 'Principal Own Occupation Booster',
        PrincipalDisabilityCapitalProtectionAccelerator: 'Principal Disability Capital Protection - Accelerator',
        PrincipalCapitalDisability: 'Principal Disability Capital Protection'
    };

    static readonly productQuoted = '/productQuoted';

    static readonly localization = {
        defaultLocalizationSourceName: 'ElevateBroker'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static QuircDecisionName = {
        Included: 'Included',
        Excluded: 'Excluded',
        Postpone: 'Postpone'
    };
    static readonly NeedsAnalysis = {
        None: 'None',
        Partial: 'Partial',
        Full: 'Full'
    };

    static readonly CommissionTypes = {
        asAndWhen: 'As-and-when',
        installment: 'Installment',
        upfront: 'Upfront',
        upfrontFull: 'Upfront-full'
    };

    static readonly PolicyReason = {
        CoverDebt: 'To cover debts',
        CoverFuneral: 'To cover the costs of a funeral',
        FamilyResponsibility: 'To provide support to my dependants',
        SomethingElse: 'Something else'
    };

    static readonly PolicyStatus = {
        AcceptedFullyUnderwrittenNewSale: 'Accepted_Fully_Underwritten_New_Sale',
        AcceptedNotUnderwritten: 'Accepted_Not_Underwritten',
        AcceptedNotUnderwrittenSecondaryCover: 'Accepted_Not_Underwritten_Secondary_Cover',
        ClaimApproved: 'ClaimApproved',
        AcceptedNotUnderwrittenEndorsed: 'Accepted_Not_Underwritten_Endorsed',
        PreInception: 'Pre_Inception',
        AcceptedFullyUnderwrittenEndorsed: 'Accepted_Fully_Underwritten_Endorsed',
        ClaimInAssessment: 'Claim_In_Assessment',
        AcceptedFullyUnderwritten_Endorsement_Pending: 'Accepted_Fully_Underwritten_Endorsement_Pending',
        LapsedCancellation: 'Lapsed_Cancellation',
        LapsedNoPayment: 'Lapsed_No_Payment'
    };

    static readonly disclaimerText = {
        StartingAndEndingsOfEveryParagraph: [
            'In order for us to provide you with##sent to us being allowed to:',
            '- Store and process you##meet our obligations under your policy\/ies.',
            '- Collect, store and process your information from thi##ms and conditions under your Elevate Life policy\/ies.',
            '- Share your information, only when absolutely nec##tain your policy\/ies, and assess claims made by you.',
            'To process your application,##against your medical history.', 'You must give us your consent to be##available on third-party databases.',
            'This may inc##our HIV status.',
            'This does not remove or red##life of your Elevate Life policies.',
            'Elevate Life (Pty) Ltd. ##insurance products.', 'All Elevate ##(FSP 7370).', 'Elevate is independent ##agent of, Centriq.',
            'Centriq and Elevate Life have##underwritten by Centriq.', 'In addition, Centriq pays ##writing your policy.', 'The following questions##person to be insured.', 'All information shared ##under the Elevate Life policy.',
            'It is really important##reject your claims.', 'At Elevate Life,##remains fully secure.', 'To provide you with##information that we request.', 'Inaccurate and/or incomplete##claims not being paid!',
            'In order for us##provide us with consent to:', 'In order for us to provide##you must consent to us being allowed to:', '-	Store and process your information shared##course of business,', '-	Collect your information from third-parties,##otherwise process such information,',
            '-	Share your information with Centriq and##any claims made by you,', '-	Share information with other insurers##risks proposed for insurance.', 'If you continue, you are choosing##Life policy offered on this page.',
            'By choosing to do so, the quote on##for an Elevate policy on these terms.', 'Instead, you will need to complete all##honestly, and accurately to get your revised quote.',
            'We will use the additional information to be##to inform the details of your revised quote.', 'It is possible that based##additional information that you share:', '- the premium for your revised qu##gher than the one on this page,',
            '- additional term##o your Elevate Life policy,', '- we choose to decline you##ation for an Elevate Life policy.',
            '- Store and process you##the normal course of business,',
            '- Collect your information from thi##and otherwise process such information,',
            '- Share your information with Centriq##and\/or assess any claims made by you,',
            '- Share information with other insurers in##assessment of risks proposed for insurance.'
        ]
    };


    static readonly Occupations = {
        SelfEmployed: 'SelfEmployed',
        Employed: 'Employed',
        Unemployed: 'Unemployed',
        Student: 'Student',
        HouseWifeOrHouseHusband: 'HouseWifeOrHouseHusband'
    };

    static readonly QuirckExtQuestionId = {
        Age: 'age',
        Gender: 'gender',
        GrossMonthlyIncome: 'monthly_income',
        NetMonthlyIncome: 'net_monthly_income',
        ExistingLifeCover: 'Existing_life',
        ExistingIncomeProtectionCover: 'Existing_IP',
        ExistingImpairmentCover: 'Existing_TPD',
        ExistingSevereIllnessCover: 'Existing_CI',
        IsSelfEmployed: 'self_employed',
        ProductCategory: 'ProductCategory',
        NeedsAnalysis: 'DND_na_indicator',
        HouseHoldIncome: 'Household_income',
        EmploymentCategory: 'Occupation',
        PolicyReason: 'Reason_for_policy',
        SmokerStatus: 'smoker_status',
        Education: 'education',
        Residency: 'Residency',
        MartitalStatus: 'marital_status',
        CustomNetIncome: 'custom_net_income',
        // CustomNeedsAssessment: 'custom_needs_assessment',
        // CustomProofOfIncome: 'custom_proof_of_income',
        // CustomCreditCheck: 'custom_credit_check',
        DndCreditCheck: 'DND_Credit_check',
        DndIncomeConfirmation: 'DND_Income_confirmation',
        DndNaIndicator: 'DND_na_indicator',
        AddingOrReplacing: 'Adding_or_replacing',
        AddingOrReplacingNa: 'Adding_or_replacing_with_NA',
        ExistingCI: 'Existing_CI',
        ReplaceExistingCover: 'replace_existing_cover',
        CoverReason: 'cover_reason',
        BusinessAssuranceType: 'business_assurance_type',
        PolicyHolderIsInsuredLife: 'policy_holder_is_insured_life'
    };

    static readonly quircSubmittingQuestionAnalysis = {
        PolicyStatusName: {
            Referred: 'Referred',
            Declined: 'Declined'
        }
    };

    static readonly ElevateMendixEndpoints = {
        addExclusion: 'quotation/quote/{QUOTE_ID}/addExclusion',
        addIndividualExclusionSet: 'quotation/quote/{QUOTE_ID}/addIndividualExclusionSet',
        addNonIndividualExclusionSet: 'quotation/quote/{QuoteGUID}/addNonIndividualExclusionSet/{ExclusionSetShortCode}',
        createBeneficiary: 'quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/beneficiaries',
        createPerson: '/bespoke/person/{GUID}',
        createQuote: 'quotation/quote',
        deleteRiderProduct: 'quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}',
        getAllEventSet: 'metadata/eventSet',
        getBeneficiariesForProductQuoted: 'quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/beneficiaries',
        getEventSetByShortCode: 'metadata/eventSet/{ShortCode}',
        getPatternSet: 'metadata/patternSet/{GUID}',
        getPerson: 'bespoke/person/{GUID}',
        getBroker: 'brokersandcommission/broker/{Broker_IDP_GUID}',
        putBroker: 'brokersandcommission/broker/{PersonGUID}',
        getPolicy: 'bespoke/policy/{PolicyGUID}',
        updateBeneficiaries: '/bespoke/policy/{PolicyGUID}/product/{ProductGUID}/updateBeneficiaries',
        getProductQuoted: 'quotation/quote/{Quote_GUID}/productQuoted',
        getAllProduct: 'products/product?Generation={GENERATION_NAME}',
        getAQuote: 'quotation/quote/{Quote_GUID}',
        getQuoteOnAPersonBasedOnRole: '/quotation/quote/getQuotesOnPerson/{PersonGUID}',
        getRateTableEntryFlat: 'megamind/rateTableEntries/getRateTableEntriesFlatData',
        getQuotedRider: 'quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/riderProductQuoted',
        acceptQuote: 'quotation/quote/{Quote_GUID}/accept/manualCheck/{ManualCheckDigit}',
        postProductQuoted: 'quotation/quote/{Quote_GUID}/productQuoted',
        createRider: '/quotation/quote/{Quote_GUID}/productQuoted/{MainProductQuoted_GUID}/riderProductQuoted',
        updateProductQuoted: '/quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}',
        updateQuote: '/quotation/quote/{Quote_GUID}',
        updateRiderProductQuoted: '/quotation/quote/{Quote_GUID}/productQuoted/{MainProductQuoted_GUID}/riderProductQuoted/{RiderProductQuoted_GUID}',
        getContract: '/bespoke/policy/{policyId}/getPolicyContract',
        getBalance: '/comoLedger/subAccount/{AccountGUID}/getBalance',
        getTransactions: '/comoLedger/subAccount/{SubAccountGUID}/getTransactions',
        getBanks: '/comoDebit/bank'
    };

    static readonly ParentSteps = {
        PersonalInfo: 'Personal Info',
        Disclaimers: 'Disclaimers',
        Medical: 'Medical',
        Additionalinfo: 'Additional info'
    };

    static readonly requirementTyoe = {
        Selectable: 'Selectable',
        Automatic: 'Automatic'
    };
    static readonly MinimumUpgradePaymentAmount = 1;

    static readonly Roles = {
        BrokerAdmin: 'Broker.Admin',
        BrokerAssistant: 'Broker.Assistant',
        BrokerConsultant: 'Broker.BrokerConsultant',
        BrokerUser: 'Broker.User',
        AdminUIAdministrator: 'AdminUI Administrator'
    };

    static readonly ProductIdentifiers = {
        Accelerator: 'Accelerator',
        Legacy: 'Legacy'
    };

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '8.6.0.0';

    static readonly ConditionListExternalId = {
        diagnosedConditionList: 'Diagnosed_condition_disclosure',
        occupationConitionList: 'occupation'
    };

    static readonly AnswerType = {
        BasicAnswerProvider: 'BasicAnswerProvider',
        MultipleSelectionAnswerProvider: 'MultipleSelectionAnswerProvider',
        SingleEntryAnswerProvider: 'SingleEntryAnswerProvider',
        SelectionAnswerProvider: 'SelectionAnswerProvider',
        DateOfBirth: 'DateOfBirth'
    };

    static readonly QuoteStatus = {
        Not_Accepted: 'Not_Accepted',
        Accepted: 'Accepted'
    };

    static readonly UnderWritingStatus = {
        NotStarted: 1,
        InProgress: 2,
        Submitted: 3
    };

    static readonly QuoteRoles = {
        Life_Insured: 'Life_Insured',
        Premium_Payer: 'Premium_Payer'
    };

    static readonly DND_Questions = {
        DND_Sex: 'DND_sex',
        DND_sales_channel: 'DND_sales_channel',
        DND_Show_products: 'DND_Show_products',
        DND_SA_ID_number: 'DND_SA_ID_number',
        DND_na_indicator: 'DND_na_indicator',
        DND_existing_life_cover_na: 'DND_existing_life_cover_na',
        DND_existing_income_protection_na: 'DND_existing_income_protection_na',
        DND_existing_disability_impairment_cover_na: 'DND_existing_disability_impairment_cover_na',
        DND_existing_severe_illness_cover_na: 'DND_existing_severe_illness_cover_na',
        DND_Credit_check: 'DND_Credit_check',
        DND_Income_confirmation: 'DND_Income_confirmation',
        DND_Scheme_Data: 'DND_Scheme_Data',
        DND_duration_on_scheme: 'DND_duration_on_scheme',
        DND_Access_to_scheme_data: 'DND_Access_to_scheme_data',
        Health_data_consent_real_time: 'Health_data_consent_real_time'
    };

    static readonly MedicalHistoryQuestions = {
        Stress: 'Stress',
        Heart: 'Heart',
        Heart_diagnosis: 'Heart_diagnosis',
        Stroke: 'Stroke',
        Cancer: 'Cancer',
        Cancer_diagnosis: 'Cancer_diagnosis',
        Paralysis: 'Paralysis',
        Paralysis_diagnosis: 'Paralysis_diagnosis',
        Condition_or_symptom: 'Condition_or_symptom',
        Diagnosed_disclosure: 'Diagnosed_disclosure',
        Treatment: 'Treatment',
        Treatment_disclosure: 'Treatment_disclosure',
        Specialists_or_tests: 'Specialists_or_tests',
        Specialists_disclosure: 'Specialists_disclosure',
        Hospitalisation: 'Hospitalisation',
        Hospitalisation_disclosure: 'Hospitalisation_disclosure',
        Time_off: 'Time_off',
        Time_off_disclosure: 'Time_off_disclosure',
        Future_appointment: 'Future_appointment',
        Future_appointment_disclosure: 'Future_appointment_disclosure',
        HIV: 'HIV',
        HIV_test_place: 'HIV_test_place',
        HIV_test_time: 'HIV_test_time',
        Last_HIV_test: 'Last_HIV_test',
        Your_HIV_status: 'Your_HIV_status'

    };

    static readonly MedicalHistoryHaveYouQuestions = [
        AppConsts.MedicalHistoryQuestions.Stress,
        AppConsts.MedicalHistoryQuestions.Heart,
        AppConsts.MedicalHistoryQuestions.Heart_diagnosis,
        AppConsts.MedicalHistoryQuestions.Stroke,
        AppConsts.MedicalHistoryQuestions.Cancer,
        AppConsts.MedicalHistoryQuestions.Cancer_diagnosis,
        AppConsts.MedicalHistoryQuestions.Paralysis,
        AppConsts.MedicalHistoryQuestions.Paralysis_diagnosis,
    ];

    static readonly MedicalHistoryLast5YearsQuestions = [
        AppConsts.MedicalHistoryQuestions.Condition_or_symptom,
        AppConsts.MedicalHistoryQuestions.Diagnosed_disclosure,
        AppConsts.MedicalHistoryQuestions.Treatment,
        AppConsts.MedicalHistoryQuestions.Treatment_disclosure,
        AppConsts.MedicalHistoryQuestions.Specialists_or_tests,
        AppConsts.MedicalHistoryQuestions.Specialists_disclosure
    ];

    static readonly MedicalHistoryLast10YearsQuestions = [
        AppConsts.MedicalHistoryQuestions.Hospitalisation,
        AppConsts.MedicalHistoryQuestions.Hospitalisation_disclosure
    ];

    static readonly MedicalHistoryMoreRecentlyQuestions = [
        AppConsts.MedicalHistoryQuestions.Time_off,
        AppConsts.MedicalHistoryQuestions.Time_off_disclosure,
        AppConsts.MedicalHistoryQuestions.Future_appointment,
        AppConsts.MedicalHistoryQuestions.Future_appointment_disclosure,

    ];

    static readonly MedicalHistoryHIVQuestions = [
        AppConsts.MedicalHistoryQuestions.HIV,
        AppConsts.MedicalHistoryQuestions.HIV_test_place,
        AppConsts.MedicalHistoryQuestions.HIV_test_time
    ];

    static readonly KeyVaultSecrets = {
        cedantId: 'QuirkApiDetails.CedantId',
        quirkRootUrl: 'QuirkApiDetails.RootUrl',
        apimElevateAccpendpoint: 'Apim.ElevateAccpendpoint',
        QuirkApiDetailsQuestionMapping: 'QuirkApiDetails.QuestionMapping',
        MicroServicesExtQuote: 'MicroServices.ExtQuote',
        GetMaxSumAssuredFuncUrl: 'GetMaxSumAssuredFuncUrl',
        AppConfigAngularQuirckMendixMappingUrl: 'AppConfigAngular.QuirckMendixMappingUrl',
        AppGetBrokerCommissionFuncUrl: 'App.GetBrokerCommissionFuncUrl',
        GeneralExclusions: 'GeneralExclusions',
        PortalSettingsAuthority: 'PortalSettings.authority',
        BrokerId: 'BrokerId',
        ServerRootAddress: 'ServerRootAddress'
    };

    static readonly QuircCategories = {
        lifeCover: 'Life Cover',
        severeIllness: 'Severe Illness Cover',
        impairmentCover: 'Impairment Cover',
        incomeProtection30: 'Income Protection 30 day WP',
        incomeProtection90: 'Income Protection 90 day WP'
    };

    static readonly AggregationCategories = {
        lifeProtection: 'Life Protection',
        severeIllnessProtection: 'Severe Illness Protection',
        impairmentProtection: 'Impairment Protection',
        disabilityProtection: 'Disability Protection',
        disabilityProtectionExtender: 'Disability Income Protection Extender',
        ownOccupation: 'Own Occupation'
    };

    static readonly Endpoints = {
        clonePolicy: '/ClonePolicy',
        getSetupTableData: '/GetSetupTableData',
        queuePolicyForEmailNotification: '/api/DirectSales/QueueUpEntityForPolicyNotification?email={EMAIL}&policy={POLICY_ID}',
        queuePolicy: '/api/IntermediateProcess/PolicySync?policyId=',
        getProductList: 'Elevate-accp/v1/products/product?Generation=TestSeptember2020_Generation_V1',
        patternsetDetails: 'Elevate-accp/v1/metadata/patternSet/',
        rateTableEntry: 'Elevate-accp/v1/calcEngine/rateTableEntry/product/',
        autoRegister: '/api/DirectSales/AutoRegisterBroker',
        brokerQuoteNotification: '/api/DirectSales/BrokerQuoteNotification',
        getAccessToken: '/api/services/app/TokenIdentiyProvider/GetTokenProvider',
        createOrUpdateApplicant: '/CreateOrUpdateApplicant',
        createOrUpdatePolicy: '/CreateOrUpdatePolicy',
        getQuestionnaireProfileVersion: '/GetQuestionnaireProfileVersion',
        getConditionList: '/GetConditionsFromQuestion',
        getPolicyList: '/GetPolicyList',
        getPolicySchedule: '/GetPolicySchedule',
        queuePolicyReferencesMail: '/api/DirectSales/QueueUpPolicyReferencesMail',
        submitQuestionnaire: '/SubmitQuestionnaire',
        updatePolicyStatus: '/UpdatePolicyStatus',
        sendNotifications: '/api/IntermediateProcess/SendNotifications',
        createQuote: '/v1/quotation/quote',
        addBeneficiary: '/api/UserBenefiaciary/AddBenficiary',
        deleteBeneficiary: '/api/UserBenefiaciary/DeleteBeneficiary/{BENEFICIARY_GUID}/Policy/{POLICY_GUID}',
        getBeneficiariesForPolicy: '/api/UserBenefiaciary/Policy/{POLICY_GUID}',
        getBeneficaryFromIdp: '/api/UserBenefiaciary/{BENEFICIARY_GUID}',
        postProductQuoted: 'Elevate-accp/v1/quotation/quote/',
        quirkDictonary: 'quirk-questions-dictonary-value.json',
        socioEconomicDimension: 'social-economic-dimension.json',
        putBeneficiaryToQuote: 'Elevate-accp/v1/quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/beneficiaries',
        addNonIndividualExclusionSet: 'Elevate-accp/v1/quotation/quote/{QuoteGUID}/addNonIndividualExclusionSet/{ExclusionSetShortCode}',
        acceptQuote: 'Elevate-accp/v1/quotation/quote/{Quote_GUID}/accept/manualCheck/{ManualCheckDigit}',
        getAQuote: 'Elevate-accp/v1/quotation/quote/{Quote_GUID}',
        getPolicy: 'Elevate-accp/v1/bespoke/policy/',
        getPerson: 'Elevate-accp/v1/bespoke/person/',
        createRider: 'Elevate-accp/v1/quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/riderProductQuoted',
        getQuotedRider: 'Elevate-accp/v1/quotation/quote/{Quote_GUID}/productQuoted/{ProductQuoted_GUID}/riderProductQuoted',
        generateIncrementalQUote: 'Elevate-accp/v1/bespoke/policy/{PolicyNumber}/generateIncrementQuote',
        fullyUnderWrittenQuote: 'Elevate-accp/v1/bespoke/policy/{PolicyNumber}/indicateFullyUnderwritten',
        getQuoteDetailsByRefernceId: 'api/ExtQuote?referenceId='

    };
    static readonly ProductNames = {
        Life: 'Life Protection'
    };

    static readonly AssessmentType = {
        MeidcalInsuranceAssessment: 'MedicalInsuranceAssessment'
    };

    static readonly FnaAssessmentTypes = {
        ClientInfo: 'ClientInfo',
        FamilyAssessment: 'FamilyAssessment',
        EarningAssessment: 'EarningAssessment',
        HomeAssessment: 'HomeAssessment',
        InsuranceAssessment: 'InsuranceAssessment',
        MedicalInsuranceAssessment: 'MedicalInsuranceAssessment',
        ExpenseAssessment: 'ExpenseAssessment',
        EstimateAssessment: 'EstimateAssessment'
    };

    static readonly NeedsCalcPerils = {
        Death: 'Death',
        SevereIllness: 'Severe Illness',
        DisabilityIncome: 'Disability income protection',
        DisabilityLump: 'Disability lump sum',
    };

    static readonly NeedsCalcDebts = {
        Car: 'Car',
        MicroLoans: 'Micro/payday loans',
        RevolvingOverdraft: 'Revolving overdraft',
        StudentLoans: 'Student loans',
        RetailCards: 'Retail cards',
        CreditCards: 'Credit cards',
        Other: 'Other'
    };

    static readonly NeedsCalcProducts = {
        CarHousehold: 'Car and household',
        Life: 'Life',
        SevereIllness: 'Severe Illness',
        DisabilityLumpSum: 'Disability Lump Sum',
        IncomeProtection: 'Income Protection',
        MedicalAid: 'Medical aid'
    };

    static readonly CoverStatus = {
        Active: 'Active'
    };

    static readonly ProductConfigCategory = {
        ComplimentaryBenefits: 'Complimentary Benefits',
    };

    static readonly CoverReasons = {
        BusinessAssurance: 'Business assurance',
        PersonalConver: 'Personal Conver'
    };

    static readonly ExistingCoverProducts = {
        Life: 'Life',
        SevereIllness: 'Severe Illness',
        Disability: 'Disability',
        IncomeProtection: 'Income Protection'
    };

    static readonly ReasonsForCover = {
        Personal: 'Personal',
        Business: 'Business',
        Group: 'Group'
    };

    static readonly ExistingCoverDisclosureTypes = {
        TypeOfCover: 'ExistingCover_TypeOfCover',
        ReasonForCover: 'ExistingCover_ReasonForCover',
        CurrentCoverAmount: 'ExistingCover_CurrentCoverAmount',
        CoverAmountBeingKept: 'ExistingCover_CoverAmountBeingKept',
        CoverBeingAltered: 'ExistingCover_CoverBeingAltered',
    };
}
